<template>
	<div class="page flex-col">
		<div class="section_1 flex-row">
			<div class="group_1 flex-col" :class="startX<=600?'wid600':''">
				<div style="width: 100vw;" v-if="startX<=600">
					<head-top :type="4" :show="-1" :startX="startX"></head-top>
				</div>
				<div class="" v-else>
					<head-top :type="4" :startX="startX"></head-top>
				</div>
				<!-- <div class="block_2 flex-col"></div> -->
				<div class="block_3 flex-col" style="margin: 0 auto;">
					<div class="section1" @click.stop="toIndex(constants.banner.picture[0])">
						<img v-if="constants.banner.audio_type==0" :src="constants.banner.picture[0].abs_url" alt=""
							style="width: 100%;height:100%">
						<video v-else style="width: 100%;height:100%" :src="constants.banner.picture[0].abs_url"></video>
					</div>
					<!-- <el-carousel height="100%" class="section1">
						<el-carousel-item v-for="item in constants.banner.picture" :key="item.id" @click.stop="toIndex(item)">
							<img v-if="constants.banner.audio_type==0" :src="item.abs_url" alt=""
								style="width: 100%;height:100%">
							<video v-else style="width: 100%;height:100%" :src="item.abs_url"></video>
						</el-carousel-item>
					</el-carousel> -->
					<div class="flex-col mainApp24 justify-between" style="z-index: 9;margin: 0 auto;height: 100%;"
						:style="startX<600?'margin-top: -4rem;min-width: 300px;':''">
						<div class="" style="margin-top:17% ;" :style="startX<600?'margin-top: 5%;':''">
							<div class="section_2 flex-row">
								<div class="text-wrapper_1">
									<span class="text_10">p</span>
									<span class="text_11">roduct</span>
									<span class="text_12">&nbsp;c</span>
									<span class="text_13">enter</span>
								</div>
							</div>
							<div class="text-wrapper_2 flex-row">
								<span class="text_14">产品中心</span>
							</div>
						</div>
						<div class="section_3 flex-row justify-end align-center">
							<span class="text_15" @click="toProduct">产品中心</span>
							<img class="thumbnail_2" referrerpolicy="no-referrer"
								src="./assets/img/psnyast5yo1oqbekk4ngctf4kg15npz0ti7e4b576f-6cb8-4f70-9804-fa786f2c205d.png" />
							<span class="text_16" style="cursor: pointer;" @click="toProduct1">{{titleName}}</span>
							<img class="thumbnail_2" referrerpolicy="no-referrer"
								src="./assets/img/psnyast5yo1oqbekk4ngctf4kg15npz0ti7e4b576f-6cb8-4f70-9804-fa786f2c205d.png" />
							<span class="text_16">{{dataMain.name}}</span>
							<img class="thumbnail_2" referrerpolicy="no-referrer"
								src="./assets/img/psnyast5yo1oqbekk4ngctf4kg15npz0ti7e4b576f-6cb8-4f70-9804-fa786f2c205d.png" />
							<span class="text_16">商品详情</span>
						</div>
					</div>
				</div>
				<div class="flex-row justify-start mainApp16" style="margin: 0 auto;margin-top: 90px;"
					:style="startX<600?'min-width: 300px;':''">
						<el-carousel height="100%" class="piCarousel">
							<el-carousel-item v-for="item in dataMain.pictures" :key="item.id">
								<!-- <h3 class="small">{{ item }}</h3> -->
								<img :src="item.abs_url" alt="" style="width: 100%;height:100%">
							</el-carousel-item>
						</el-carousel>
						<div class="piName flex-col">
							<span>{{dataMain.name}}</span>
							<span>发布于：{{dataMain.create_time}}</span>
						</div>
				</div>
				<div class="picDateil" style="" v-if="dataMain.content!=''">产品详情</div>
				<div class="mainApp16"  v-if="dataMain.content!=''" style="margin: 0 auto;margin-top: 70px;" v-html="dataMain.content"></div>
			</div>
		</div>
		<div class="" v-if="startX>600">
			<body-footer></body-footer>
		</div>
		<div class="" v-else>
			<body-footer1></body-footer1>
		</div>
		<!-- <el-dialog :class="startX<=600?'el-dialog1':''" :visible.sync="dialogVisible" @close="dialogVisible=false">
			<el-carousel trigger="click" ref="carousel" :autoplay="false" class="dialog" :initial-index="showNum"
				@change="caroChange">
				<el-carousel-item v-for="(item,index) in list" :key="index" class="flex-align-justify flex-col">
					<img :src="require('./assets/img/'+item.image)" class="car_img" alt="">
					<div class="text1">{{ item.name }}</div>
					<div class="text2">{{ item.time }}</div>
				</el-carousel-item>
			</el-carousel>
			<div></div>
		</el-dialog> -->
	</div>
</template>
<script>
	import headTop from "../../components/headTop.vue";
	import bodyFooter from "../../components/bodyFooter.vue";
	import bodyFooter1 from "../../components/bodyFooter1.vue";
	import {
		product,
		productDetail
	} from "../../util/api";
	export default {
		data() {
			return {
				constants: {
					banner: {}
				},
				dataMain: {},
				startX: 1200,
				titleName:''
			};
		},
		components: {
			headTop,
			bodyFooter,
			bodyFooter1
		},
		mounted() {
			console.log(this.$route.params, "id");
			this.getDate()
			this.startX = document.body.clientWidth;
			window.onresize = () => {
				return (() => {
					this.startX = document.body.clientWidth;
				})();
			};
		},
		watch: {
			startX(val, oldval) {
				if (val != oldval) {
					// console.log(this.startX, "屏幕宽度");
				}
			},
			lang(val, oldval) {
				if (val != oldval) {
					this.getDate()
					// console.log(this.startX, this.widNum, "屏幕宽度");
				}
			},
			product_id(val, oldval) {
				if (val != oldval) {
					this.getDate()
					// console.log(this.startX, this.widNum, "屏幕宽度");
				}
			},
		},
		computed: {
			lang() {
				return this.$store.getters.getLang;	
			},
			product_id() {
				return this.$route.params.id
			},
			category_id() {
				return this.$route.params.cid
			}
		},
		methods: {
			toProduct1(){
				this.$router.push("/product/1");
			},
			toProduct(){
				this.$router.push("/product/"+this.category_id);
			},
			getDate() {
				product({
					lang: this.lang,
					category_id: this.category_id
				}).then((res) => {
					this.constants = res.data
					let name
					this.constants.category.forEach(ele => {
						if (ele.choose == 1) {
							if (ele._child.length > 0) {
								ele._child.forEach(ele1 => {
									if (ele1.choose == 1) {
										name = ele1.name
									} else {
										name = ele.name
									}
								})
							}
						}
					})
					this.titleName=name
					console.log(name);
				})
				// console.log(productDetail);
				productDetail({
					lang: this.lang,
					product_id: this.product_id
				}).then((res) => {
					this.dataMain = res.data
					// this.title = res.data.header_nav;
				})
			},
			toIndex(v) {
				if (v.target_rule==1){
					this.$router.push("/");
				}else if (v.target_rule==2){
					this.$router.push("/aboutUs");
				}else if (v.target_rule==3){
					let id = v.param
					this.$router.push("/news/"+id);
				}else if (v.target_rule==4){
					let news_id = v.param
					this.$router.push("/newDetail/"+news_id);
				}else if (v.target_rule==5){
					let id = v.param
					console.log(id);
					this.$router.push("/product/"+id);
				}else if (v.target_rule==6){
					this.$router.push("/companies");
				}else if (v.target_rule==7){
					this.$router.push("/Investor");
				}else if (v.target_rule==8){
					this.$router.push("/invite");
				}else if (v.target_rule==9){
					this.$router.push("/contact");
				}
			},
		},
	};
</script>
<style scoped lang="css" src="./assets/index.css" />
<style lang="scss" scoped>
	::v-deep .van-icon {
		display: none;
	}

	.wid600 {
		width: 100vw;

		.block_3 {
			width: 100vw;
			min-width: 66.667rem;
			max-width: 200rem;
			// height: 18vw;
			min-height: 12rem;
			max-height: 36.333rem;
			// padding: 0 7.667rem;
			margin-top: 13.333rem;

			.section1 {
				// width: auto;
				min-height: auto;
			}

			.section_2 {
				width: auto;
				height: auto;
				margin: 0;
				margin-top: 13.667rem;

				.text-wrapper_1 {
					font-size: 6rem;
					font-weight: normal;
					line-height: 5.333rem;
					text-transform: uppercase;
				}
			}

			.text-wrapper_2 {
				width: auto;
				height: auto;
				margin: 0;
				margin-top: 2rem;

				.text_14 {
					width: auto;
					height: auto;
					font-size: 4rem;
					line-height: 5.333rem;
				}
			}

			.section_3 {
				width: auto;
				height: auto;
				margin: 0;
				margin-top: 2.333rem;
				justify-content: flex-end;

				.text_15 {
					width: auto;
					height: auto;
					font-size: 3.333rem;
					line-height: 3.333rem;
				}

				.thumbnail_2 {
					width: 1rem;
					height: 1.667rem;
					margin: 0 2rem;
				}

				.text_16 {
					width: auto;
					height: auto;
					font-size: 3.333rem;
					line-height: 3.333rem;
					margin: 0;
				}
			}
		}

	}

	.el-dialog1 ::v-deep .el-dialog {
		width: 100%;
		margin-top: 20vh !important;

		.dialog {
			height: 85rem;

			.el-carousel__container {
				height: 85rem;

				.el-carousel__arrow {
					top: 39%;
					height: 8rem;
					width: 8rem;
				}

				.car_img {
					width: 85%;
					height: auto;
				}

				.text1 {
					font-size: 4.333rem;
					margin-top: 6.667rem;
				}

				.text2 {
					font-size: 3rem;
					margin-top: 2.5rem;
				}
			}
		}

	}
</style>